<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('destination.destination_table.id') }}</td>
          <td>{{ $t('destination.destination_table.partner_id') }}</td>
          <td>{{ $t('destination.destination_table.name') }}</td>
          <td>{{ $t('business.business_table.address') }}</td>
          <td>{{ $t('destination.destination_table.branding') }}</td>
          <td>{{ $t('destination.destination_table.creation_date') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('destination.destination_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'destination_details', params: { destinationId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('destination.destination_table.partner_id') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('destination.destination_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('destination.destination_table.address') }}:</h5>
            <span v-if="item.address && item.address.street">{{ item.address | addressOneLine }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('destination.destination_table.branding') }}:</h5>
            <span>
              <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
                {{ item.branding.name }} ({{ item.branding_id }})
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td>
            <h5>{{ $t('destination.destination_table.creation_date') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'destinations-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('destination', ['listPageCount', 'listPageNumber', 'listKeyword']),
    pagination: {
      get() {
        return this.listPageNumber;
      },
      set(page) {
        this.executeAction(
          {
            action: 'destination/listDestinations',
          },
          {
            page,
            keyword: this.listKeyword,
          },
        );
      },
    },
  },
};
</script>
