<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_projects.projects_table.id') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.reference_id') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.name') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.program') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.type') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.offers_available') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.batches_pending') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.batches_generated') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.batches_delivered') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.cards_paused') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.cards_pending_activation') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.cards_active') }}</td>
          <td>{{ $t('instant_card_projects.projects_table.created') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.id') }}:</h5>
            <router-link :to="{ name: 'instant_card_project_details', params: { projectId: item.id } }">
              <span>{{ item.id }}</span>
            </router-link>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.reference_id') }}:</h5>
            <span v-if="item.reference_id">{{ item.reference_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.program') }}:</h5>
            <span :class="!item.partner_id ? 'info-not-available' : ''">{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.type') }}:</h5>
            <span>{{ $t(`instant_card_projects.details.project_information_section.type.options.${item.type}`) }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.offers_available') }}:</h5>
            <span v-if="item.type === 'reward'">{{ item.offers_available }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.batches_pending') }}:</h5>
            <span>{{ item.batches_pending }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.batches_generated') }}:</h5>
            <span>{{ item.batches_generated }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.batches_delivered') }}:</h5>
            <span>{{ item.batches_delivered }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.cards_paused') }}:</h5>
            <span>{{ item.cards_paused }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.cards_pending_activation') }}:</h5>
            <span>{{ item.cards_pending_activation }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.projects_table.cards_active') }}:</h5>
            <span>{{ item.cards_active }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.projects_table.created') }}:</h5>
            <span :class="!item.creation_date ? 'info-not-available' : ''">{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _forEach from 'lodash/forEach';

export default {
  name: 'instant-card-projects-table',
  mixins: [actionErrorTrackable, security],
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('instantCardProject', ['listPageCount', 'listPageNumber', 'listKeyword', 'listPartnerId']),
  },
  methods: {
    async getBatchQuantities(instant_card_project_id) {
      const projectBatches = await this.fetchBatches(instant_card_project_id);
      let quantity = 0;

      _forEach(projectBatches, (batch) => {
        quantity += batch.quantity;
      });

      return quantity;
    },
    async fetchBatches(instant_card_project_id) {
      const batches = await this.executeAction({ action: 'instantCardProject/listProjectBatches' }, { id: instant_card_project_id, page: 1 });
      this.dataReady = true;
      return batches;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardProject/listProjects',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
        },
      );
    },
  },
};
</script>
