// Page Level Dom Explorer
// Mostly shared DOM (e.g. menus, modals and such that do share a common class and can't really be changed)

export const page = {
  get openMenu() {
    return cy.get('.menuable__content__active .v-list');
  },

  selectMenuItemByOrder(order) {
    return cy.get(`.menuable__content__active .v-list div:nth-child(${order})`);
  },

  get openModal() {
    return cy.get('.v-dialog.v-dialog--active');
  },

  modalActions: {
    submit() {
      return cy
        .get('.v-dialog.v-dialog--active')
        .find('[data-test=submit-button]')
        .click();
    },
  },

  get confirmModal() {
    return cy.get('.v-dialog.v-dialog--active .modal-card--card-title').parent();
  },
};
