import Vue from 'vue';
import { page } from '../../cypress/support/dom-explorer/common/page-explorer';

const service = {
  async getStore(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}`);
    return response.data.data;
  },
  async createStore(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores`, payload);
    return response.data.data;
  },
  async searchStores(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/stores/search', page, limit, { keyword })}`,
    );
    return response.data.data;
  },
  async listStores(keyword, business_id, franchise_id, destination_id, card_acceptor, status, type, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (business_id) {
      params.business_id = business_id;
    }
    if (franchise_id) {
      params.franchise_id = franchise_id;
    }
    if (destination_id) {
      params.destination_id = destination_id;
    }
    if (card_acceptor) {
      params.card_acceptor = card_acceptor;
    }
    if (status) {
      params.status = status;
    }
    if (type) {
      params.type = type;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/stores', page, limit, params)}`,
    );
    return response.data.data;
  },

  async listStoreDestinations(storeId, page, limit) {
    const params = {};
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/stores/${storeId}/destinations`, page, limit)}`,
    );
    return response.data.data;
  },

  async addDestinationToStore(storeId, destinationId) {
    const params = {};

    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/stores/${storeId}/destinations/${destinationId}`,
    );
    return response.data.data;
  },

  async updateStore(id, params) {
    const payload = params;
    delete payload.business_id;
    delete payload.branding_id;
    delete payload.system_status;
    delete payload.coordinates;
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}`, payload);
    return response.data.data;
  },
  async createStoreNote(store_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${store_id}/notes`, payload);
    return response.data.data;
  },
  async deleteStoreNote(store_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${store_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listStoreNotes(page, limit, store_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/stores/${store_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async updateStoreCoordinates(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/coordinates`, payload);
    return response.data.data;
  },
  async regenerateStoreCoordinates(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/regenerate-coordinates`);
    return response.data.data;
  },
  async createStoreCardAcceptor(storeId, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${storeId}/card-acceptors`, payload);
    return response.data.data;
  },
  async updateStoreCardAcceptor(id, payload) {
    delete payload.store_id;
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/card-acceptors/${id}`, payload);
    return response.data.data;
  },
  async checkStoreCardAcceptorVerification(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/card-acceptors/${id}/check`);
    return response.data.data;
  },
  async removeStoreCardAcceptor(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/card-acceptors/${id}`);
    return response.data.data;
  },
  async getStoreCardAcceptorsByStore(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/card-acceptors`);
    return response.data.data.items;
  },
  async listStorePublishers(id, page, limit, keyword) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/stores/${id}/publishers`, page, limit, { keyword })}`,
    );
    return response.data.data;
  },
  async linkBrandingToStore(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/branding`, payload);
    return response.data.data;
  },
  async linkBusinessToStore(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/business`, payload);
    return response.data.data;
  },
  async unlinkBrandingFromStore(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/branding`);
    return response.data.data;
  },
  async unlinkBusinessFromStore(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/stores/${id}/business`);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, business_id, franchise_id, destination_id, card_acceptor, status, type } = {}) {
  let path = startPath;
  if (page || limit || keyword || business_id || card_acceptor || status || type) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (franchise_id) {
    queryStrings.push(['franchise_id', franchise_id]);
  }
  if (destination_id) {
    queryStrings.push(['destination_id', destination_id]);
  }
  if (card_acceptor) {
    queryStrings.push(['card_acceptor', card_acceptor]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }
  if (type) {
    queryStrings.push(['type', type]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
