<template>
  <v-flex class="view-wallet_definition-form">
    <h1 hidden>{{ $t('wallet_definition.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap>
          <v-flex xs12 sm6 md5 class="form-search--main-input-wrap">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('wallet_definition.form.total_results_message')"
              class="wallet_definition--search-field"
              :label="$t('wallet_definition.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:100'"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
          </v-flex>
          <v-flex xs12 sm6 md7 class="form-search--button-wrap">
            <button-primary
              class="wallet-definition--btn-search"
              :text="$t('wallet_definition.form.submit')"
              :loading="working"
              @click="submit"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && walletDefinitions.length === 0" :text="$t('wallet_definition.no_wallet_definition_found')"></alert-warning>
      <card
        v-if="dataReady && walletDefinitions.length > 0"
        icon="fal fa-envelope-open-dollar"
        :title="`${$t('wallet_definition.form.data_section.title')}`"
      >
        <template slot="headerZoneRight">
          <label-text
            class="text-sm-center"
            :label="$t('wallet_definition.form.data_section.wallet_definitions_count')"
            :value="listItemCount || '0'"
          ></label-text>
        </template>
        <template slot="content">
          <wallet-definitions-table :items="walletDefinitions"></wallet-definitions-table>
        </template>
      </card>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import WalletDefinitionsTable from './WalletDefinitionsTable';
import AlertWarning from '@/components/system/Alerts/AlertWarning';

export default {
  name: 'search-wallet-definition-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, WalletDefinitionsTable },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'walletDefinition/clearListWalletDefinitions' });
  },
  data() {
    return {
      keyword: '',
      searched: '',
      page: 1,
      dataReady: false,
      showCreateWalletDefinitionModal: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    if (this.$route.query.search) {
      this.page = 1;
      this.keyword = this.$route.query.search;
    }
    this.$route.query.search ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('walletDefinition', ['walletDefinitions', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  methods: {
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (forceSearch !== true) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch === true) {
        this.$router.replace({ query: { search: this.keyword } });
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction({ action: 'walletDefinition/listWalletDefinitions' }, { keyword: this.keyword, page });
        this.dataReady = true;
      }
    },
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.$validator.reset();
    },
  },
};
</script>
