<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('business.funding_items_table.date') }}</td>
          <td>{{ $t('business.funding_items_table.reason') }}</td>
          <td align="right">{{ $t('business.funding_items_table.amount') }}</td>
          <td>{{ $t('business.funding_items_table.eft_report_file_number') }}</td>
          <td>{{ $t('business.funding_items_table.eft_report_creation_date') }}</td>
          <td>{{ $t('business.funding_items_table.eft_report_status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('business.funding_items_table.date') }}:</h5>
            <span>{{ item.creation_date | formatShortDateTimeInverted }}</span>
          </td>
          <td>
            <h5>{{ $t('business.funding_items_table.reason') }}:</h5>
            <span>{{ item.reason }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('business.funding_items_table.amount') }}:</h5>
            <span>{{ item.amount | currency | dollarSignI18n }}</span>
          </td>
          <td>
            <h5>{{ $t('business.funding_items_table.eft_report_file_number') }}:</h5>
            <span v-if="item.eft_report_file_number">{{ item.eft_report_file_number }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('business.funding_items_table.eft_report_creation_date') }}:</h5>
            <span v-if="item.eft_report_creation_date">{{ item.eft_report_creation_date | formatShortDateTimeInverted }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('business.funding_items_table.eft_report_status') }}:</h5>
            <span v-if="item.eft_report_status"><eft-item-status-view :status="item.eft_report_status"/></span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import EftItemStatusView from '../EftItemStatusView';

export default {
  name: 'business-funding-items-table',
  mixins: [actionErrorTrackable, security],
  components: { EftItemStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.searchPageNumber;
  },
};
</script>
