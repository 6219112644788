<template>
  <view-main title="page_title.store_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.stores')" :to="{ name: 'stores' }" :last-label="store.name" />
    <store-information slot="content" />
    <store-location-type-place v-if="store.type === 'place'" slot="content" />
    <store-location-type-web v-if="store.type === 'web'" slot="content" />
    <store-location-type-custom v-if="store.type === 'custom'" slot="content" />
    <store-owner slot="content" />
    <store-branding slot="content" />
    <store-publishers slot="content" />
    <store-destinations slot="content" />
    <store-card-acceptors slot="content" />
    <store-notes slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import StoreInformation from './detail/StoreInformation.vue';
import StoreOwner from './detail/StoreBusiness.vue';
import StoreBranding from './detail/StoreBranding.vue';
import StorePublishers from './detail/StorePublishers';
import StoreCardAcceptors from './detail/StoreCardAcceptors.vue';
import StoreDestinations from './detail/StoreDestinations.vue';
import StoreLocationTypePlace from './detail/StoreLocationTypePlace.vue';
import StoreLocationTypeWeb from './detail/StoreLocationTypeWeb.vue';
import StoreLocationTypeCustom from './detail/StoreLocationTypeCustom.vue';
import StoreNotes from './detail/StoreNotes.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'store-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: {
    StoreInformation,
    StoreLocationTypePlace,
    StoreLocationTypeWeb,
    StoreLocationTypeCustom,
    StoreOwner,
    StoreBranding,
    StorePublishers,
    StoreCardAcceptors,
    StoreDestinations,
    StoreNotes,
  },
  props: {
    storeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('store', ['store']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async storeId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const store = await this.executeAction({ action: 'store/getStore' }, this.storeId);
      if (!store) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
