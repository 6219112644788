<template>
  <card-modal
    icon=""
    :title="$t('instant_card_projects.create_action.title')"
    context="createProject"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_projects.create_action.button_cancel"
    submit_text="instant_card_projects.create_action.button_save"
    data-test="project--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('instant_card_projects.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="project.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('instant_card_projects.create_action.name')"
          data-test="add-project--name"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('instant_card_projects.create_action.reference_id')"
          name="reference_id"
          v-validate="'max:100'"
          :error-messages="errors.collect('reference_id')"
          v-model="project.reference_id"
          data-vv-name="reference_id"
          maxlength="100"
          :data-vv-as="$t('instant_card_projects.create_action.reference_id')"
          data-test="add-project--reference_id"
        ></v-text-field>
        <h-autocomplete
          v-model="project.partner_id"
          :label="$t('instant_card_projects.create_action.program') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('program')"
          name="program"
          data-vv-name="program"
          data-vv-as="program"
          search-action="partner/autocompleteSearch"
          data-test="add-project--program"
          no-filter
        >
        </h-autocomplete>
        <v-select
          name="type"
          v-model="project.type"
          v-validate="{ required: true }"
          :error-messages="errors.collect('type')"
          data-vv-name="type"
          :data-vv-as="$t(`instant_card_projects.create_action.type.title`)"
          :items="types"
          item-text="label"
          item-value="type"
          :label="$t(`instant_card_projects.create_action.type.title`)"
        ></v-select>

        <v-select
          name="structure"
          v-if="isRewardType"
          v-model="project.structure"
          v-validate="{ required: true }"
          :error-messages="errors.collect('structure')"
          data-vv-name="structure"
          :data-vv-as="$t(`instant_card_projects.create_action.structure.title`)"
          :items="structures"
          item-text="label"
          item-value="type"
          :label="$t(`instant_card_projects.create_action.structure.title`)"
        ></v-select>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import InstantCardProjectStructure from '@/enums/instant-card-project-structure';
import InstantCardProjectType from '@/enums/instant-card-project-type';

export default {
  name: 'create-project-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      project: {},
      structures: [
        { type: InstantCardProjectStructure.SINGLE, label: this.$t('instant_card_projects.create_action.structure.options.single') },
        { type: InstantCardProjectStructure.MULTIPLE, label: this.$t('instant_card_projects.create_action.structure.options.multiple') },
      ],
      types: [
        { type: InstantCardProjectType.BLANK, label: this.$t('instant_card_projects.create_action.type.options.blank') },
        { type: InstantCardProjectType.REWARD, label: this.$t('instant_card_projects.create_action.type.options.reward') },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    isRewardType() {
      return this.project.type === InstantCardProjectType.REWARD;
    },
  },
  methods: {
    clear() {
      this.project = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$router.push({ name: 'instant_card_project_details', params: { projectId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.project;

        await this.executeAction(
          {
            action: 'instantCardProject/createProject',
            name: 'createProject',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
