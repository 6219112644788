<template>
  <v-flex class="view-business-form">
    <h1 hidden>{{ $t('business.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap>
          <v-flex xs12 sm6 md5 class="form-search--main-input-wrap">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('business.form.total_results_message')"
              class="business--search-field"
              :label="$t('business.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:100'"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
          </v-flex>
          <v-flex xs12 sm6 md7 class="form-search--button-wrap">
            <button-primary class="business--btn-search" :text="$t('business.form.submit')" :loading="working" type="submit"></button-primary>
            <button-primary
              data-test="business--btn-add"
              id="business--btn-add"
              class="business-form--btn-add"
              :loading="working"
              :text="$t('business.form.add')"
              @click="openCreateBusinessModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && businesses.length === 0" :text="$t('business.no_business_found')"></alert-warning>
      <card v-if="dataReady && businesses.length > 0" icon="fal fa-building" :title="`${$t('business.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('business.form.data_section.businesses_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <businesses-table :items="businesses"></businesses-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateBusinessModal"
      ><create-business-action
        v-if="showCreateBusinessModal"
        @close="closeCreateBusinessModal"
        @created="createBusinessSuccess"
      ></create-business-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import BusinessesTable from './BusinessesTable';
import CreateBusinessAction from '../actions/CreateBusinessAction';
import AlertWarning from '@/components/system/Alerts/AlertWarning';

export default {
  name: 'view-business-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, BusinessesTable, CreateBusinessAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'business/clearListBusinesses' });
  },
  data() {
    return {
      keyword: '',
      searched: '',
      page: 1,
      dataReady: false,
      showCreateBusinessModal: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    if (this.$route.query.search) {
      this.page = 1;
      this.keyword = this.$route.query.search;
    }
    this.$route.query.search ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('business', ['businesses', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  methods: {
    openCreateBusinessModal() {
      this.showCreateBusinessModal = true;
    },
    closeCreateBusinessModal() {
      this.showCreateBusinessModal = false;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (forceSearch !== true) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch === true) {
        this.$router.replace({ query: { search: this.keyword } });
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction({ action: 'business/listBusinesses' }, { keyword: this.keyword, page });
        this.dataReady = true;
      }
    },
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.$validator.reset();
    },
    async createBusinessSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.create_action.success' });
      this.closeCreateBusinessModal();
    },
  },
};
</script>
