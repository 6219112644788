<template>
  <table class="responsive-table" id="store-destinations-table">
    <thead>
      <tr>
        <td>{{ $t('store.destinations_table.id') }}</td>
        <td>{{ $t('store.destinations_table.partner_id') }}</td>
        <td>{{ $t('store.destinations_table.name') }}</td>
        <td>{{ $t('store.destinations_table.address') }}</td>
        <td>{{ $t('store.destinations_table.branding_id') }}</td>
        <td>{{ $t('store.destinations_table.creation_date') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('store.destinations_table.id') }}:</h5>
          <router-link :to="{ name: 'destination_details', params: { destinationId: item.id } }">
            {{ item.id }}
          </router-link>
        </td>
        <td>
          <h5>{{ $t('store.destinations_table.partner_id') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('store.destinations_table.name') }}:</h5>
          <span>{{ item.name }}</span>
        </td>
        <td>
          <h5>{{ $t('store.destinations_table.fields.address') }}:</h5>
          <span v-if="item.address && item.address.street">{{ item.address | addressOneLine }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('store.destinations_table.branding_id') }}:</h5>
          <span>
            <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
              {{ item.branding.name }} ({{ item.branding_id }})
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td>
          <h5>{{ $t('store.destinations_table.fields.creation_date') }}:</h5>
          <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td>
          <link-secondary-action slot="labelTextLink" @click="openDeleteStorePublisherModal(item)">{{
            $t('store.destinations_table.delete_link')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'store-destinations-table',
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    openDeleteStorePublisherModal(storeDestination) {
      this.$emit('store-destination-delete-action', storeDestination);
    },
  },
};
</script>
