<template>
  <card :title="$t('workstation.details.workstation_registration_request_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('workstation.details.workstation_registration_request_section.count')"
        :value="listWorkstationRegistrationRequestsItemCount || '0'"
      ></label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`workstation.details.workstation_information_section.actions`)" :actions="actions" />
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-confirm
        :show-modal="showGenerateRequestModal"
        :title="$t('workstation.generate_request_action.title')"
        context="generateRequest"
        :text="$t('workstation.generate_request_action.confirm.text')"
        :cancel_text="'workstation.generate_request_action.button_cancel'"
        :submit_text="'workstation.generate_request_action.button_submit'"
        :submit_working="working"
        @submit="generateRequest"
        @close="closeGenerateRequestModal"
      ></modal-confirm>

      <workstation-registration-request-table
        v-if="dataReady && workstationRegistrationRequests.length"
        :items="workstationRegistrationRequests"
        :workstation-id="workstation.id"
        :partner-id="workstation.partner_id"
      />
      <label-none v-if="workstationRegistrationRequests.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import WorkstationRegistrationRequestTable from './WorkstationRegistrationRequestTable';

import { mapGetters } from 'vuex';

export default {
  name: 'workstation-registration-request',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { WorkstationRegistrationRequestTable },
  data() {
    return {
      page: 1,
      dataReady: false,
      showGenerateRequestModal: false,
      actions: [
        {
          slotName: 'GenerateRequestModal',
          name: this.$t('workstation.details.workstation_information_section.generate_request'),
          action: () => {
            this.openGenerateRequestModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showGenerateRequestFeature();
          },
        },
      ],
    };
  },
  async created() {
    await this.fetchWorkstationRegistrationRequests();
    this.page = this.listWorkstationRegistrationRequestsPageNumber;
  },
  watch: {
    async workstation() {
      await this.fetchWorkstationRegistrationRequests();
      this.page = 1;
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('partnerSecurity', [
      'workstation',
      'workstationRegistrationRequests',
      'listWorkstationRegistrationRequestsItemCount',
      'listWorkstationRegistrationRequestsPageNumber',
    ]),
  },
  methods: {
    async fetchWorkstationRegistrationRequests() {
      this.dataReady = false;
      if (this.workstation) {
        await this.executeAction(
          { action: 'partnerSecurity/listWorkstationRegistrationRequests' },
          { partner_id: this.workstation.partner_id, workstation_id: this.workstation.id },
        );
      }
      this.dataReady = true;
    },
    showGenerateRequestFeature() {
      return this.workstation.status === 'active' || this.workstation.status === 'created' || this.workstation.status === 'suspended';
    },
    openGenerateRequestModal() {
      this.showGenerateRequestModal = true;
    },
    closeGenerateRequestModal() {
      this.showGenerateRequestModal = false;
    },
    async generateRequest() {
      await this.executeAction(
        {
          action: 'partnerSecurity/generateRequest',
          name: 'generateRequest',
          success: this.generateRequestSuccess,
        },
        {
          workstation_id: this.workstation.id,
          partner_id: this.workstation.partner_id,
          approbation_required: false,
        },
      );
    },
    async generateRequestSuccess() {
      this.showGenerateRequestModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation.generate_request_action.success' });
      this.closeGenerateRequestModal();
    },
  },
};
</script>
