<template>
  <card :title="$t('destination.details.destination_information_section.title')" icon="fal fa-shopping-bag" id="destination-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('destination.details.destination_information_section.id')}`" :value="destination.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ destination.id }}
            <button class="clipboard" v-clipboard:copy="destination.id ? destination.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('destination.details.destination_information_section.partner_id')}`" :value="destination.partner_id"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('destination.details.destination_information_section.name')}`" :value="destination.name"></label-text>
      <div v-if="showUpdateDestinationFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdateDestinationModal">
        <update-destination-action
          v-if="showUpdateDestinationModal"
          :destination="destination"
          @close="closeUpdateDestinationModal"
          @updated="updateDestinationSuccess"
        >
        </update-destination-action
      ></modal-action>

      <label-text :label="$t(`destination.details.destination_information_section.branding`)" :value="destination.branding_id">
        <p slot="labelTextLink">
          <router-link v-if="destination.branding_id" :to="{ name: 'branding_details', params: { brandingId: destination.branding_id } }">
            {{ `${destination.branding.name} (${destination.branding_id})` }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateDestinationAction from '../actions/UpdateDestinationAction';
import { mapGetters } from 'vuex';

export default {
  name: 'destination-information',
  components: { UpdateDestinationAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showUpdateDestinationModal: false,
      actions: [
        {
          slotName: 'editDestination',
          name: $t('destination.details.destination_information_section.update_destination'),
          action: () => this.openUpdateDestinationModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateDestinationFeature(),
        },
      ],
    };
  },
  methods: {
    openUpdateDestinationModal() {
      this.showUpdateDestinationModal = true;
    },
    closeUpdateDestinationModal() {
      this.showUpdateDestinationModal = false;
    },
    async updateDestinationSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'destination.details.destination_information_section.update_action.success' },
      );
      this.closeUpdateDestinationModal();
    },
    showUpdateDestinationFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
  },
  computed: {
    ...mapGetters('destination', ['destination']),
  },
};
</script>
