<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('store.stores_table.id') }}</td>
          <td>{{ $t('store.stores_table.name') }}</td>
          <td>{{ $t('store.stores_table.type') }}</td>
          <td>{{ $t('store.stores_table.location') }}</td>
          <td>{{ $t('store.stores_table.owner') }}</td>
          <td>{{ $t('store.stores_table.branding') }}</td>
          <td>{{ $t('store.stores_table.franchise') }}</td>
          <td>{{ $t('store.stores_table.card_acceptors') }}</td>
          <td>{{ $t('store.stores_table.status') }}</td>
          <td>{{ $t('store.stores_table.created') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('store.stores_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'store_details', params: { storeId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.type') }}:</h5>
            <span>{{ $t(`store.types.${item.type}`) }}</span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.location') }}:</h5>
            <span v-if="item.location && item.location.address">{{ fullAddress(item) }}</span>
            <span v-if="item.location && item.location.url">{{ item.location.url.fr + ', ' + item.location.url.en }}</span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.owner') }}:</h5>
            <span>
              <router-link v-if="item.business.name" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
                {{ item.business.name }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.branding') }}:</h5>
            <span>
              <router-link v-if="item.branding.name" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
                {{ item.branding.name }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.franchise') }}:</h5>
            <span>
              <router-link v-if="item.franchise_id" :to="{ name: 'franchise_details', params: { franchiseId: item.franchise_id } }">
                {{ item.franchise.name }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td align="center">
            <h5>{{ $t('store.stores_table.card_acceptors') }}:</h5>
            <span>{{ item.card_acceptors_count }}</span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.status') }}:</h5>
            <span><store-status-view :store-object="item"/></span>
          </td>
          <td>
            <h5>{{ $t('store.stores_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>

      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('store.stores_table.maximum_page_reached', { page_count: listPageCount, max_pages: maxPages }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import StoreStatusView from '../StoreStatusView';

export default {
  name: 'stores-table',
  mixins: [actionErrorTrackable, security],
  components: { StoreStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
      maxPages: 20,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('store', [
      'listPageCount',
      'listPageNumber',
      'listKeyword',
      'listBusinessId',
      'listStatus',
      'listType',
      'listCardAcceptor',
      'listDestinationId',
      'listFranchiseId',
    ]),
    truncatedListPageCount() {
      return Math.min(this.listPageCount, this.maxPages);
    },
    maximumPageReached() {
      return this.listPageCount > this.maxPages;
    },
  },
  methods: {
    fullAddress(item) {
      if (item.location) {
        return `${item.location.address.street}, ${item.location.address.city}, ${item.location.address.state}, ${item.location.address.country}, ${item.location.address.zip}`;
      }
      return '';
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'store/listStores',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          business_id: this.listBusinessId,
          status: this.listStatus,
          type: this.listType,
          card_acceptor: this.listCardAcceptor,
          destination_id: this.listDestinationId,
          franchise_id: this.listFranchiseId,
        },
      );
    },
  },
};
</script>
