<template>
  <card-modal
    icon=""
    :title="$t('store.create_action.title')"
    context="createStore"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.create_action.button_cancel"
    submit_text="store.create_action.button_save"
    data-test="store--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('store.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="store.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('store.create_action.name')"
          data-test="add-store--name"
          required
        ></v-text-field>
        <v-select
          :items="statusTranslated"
          name="status"
          v-validate="'required'"
          v-model="store.status"
          :label="$t('store.create_action.status') + '*'"
          :error-messages="errors.collect('status')"
          data-vv-name="status"
          :data-vv-as="$t('store.create_action.status')"
          item-text="text"
          item-value="key"
          data-test="add-store--status"
        ></v-select>
        <h-autocomplete
          v-model="store.franchise_id"
          :label="$t('store.create_action.franchise')"
          v-validate="'max:50'"
          :error-messages="errors.collect('franchise')"
          name="franchise"
          data-vv-name="franchise"
          data-vv-as="franchise"
          search-action="franchise/autocompleteSearch"
          data-test="add_store--franchise"
          no-filter
          clearable
        >
        </h-autocomplete>
        <v-text-field
          :label="$t('store.create_action.complementary_info_label.fr')"
          name="complementary_info_fr"
          data-vv-name="complementary_info_fr"
          :data-vv-as="$t('store.create_action.complementary_info_label.fr')"
          v-model="store.complementary_info.fr"
          :error-messages="errors.collect('complementary_info_fr')"
          v-validate="'max:256'"
          maxlength="256"
        ></v-text-field>
        <v-text-field
          :label="$t('store.create_action.complementary_info_label.en')"
          name="complementary_info_en"
          data-vv-name="complementary_info_en"
          :data-vv-as="$t('store.create_action.complementary_info_label.en')"
          v-model="store.complementary_info.en"
          :error-messages="errors.collect('complementary_info_en')"
          v-validate="'max:256'"
          maxlength="256"
        ></v-text-field>
        <v-flex xs12>
          <v-checkbox
            v-model="store.fuzzy_trx_enabled"
            :label="$t('store.create_action.fuzzy_trx_enabled')"
            data-vv-name="fuzzy_trx_enabled"
            name="fuzzy_trx_enabled"
            :error-messages="errors.collect('fuzzy_trx_enabled')"
          />
        </v-flex>
        <v-flex xs12>
          <p>{{ $t('store.create_action.type_title') }}*</p>
          <v-radio-group
            row
            v-model="store.type"
            name="type"
            v-validate="'required'"
            :error-messages="errors.collect('type')"
            data-vv-name="type"
            :data-vv-as="$t('store.create_action.type_title')"
            data-test="add-store--type"
          >
            <v-radio :label="$t('store.create_action.type_place')" value="place" data-test="add-store--type-place"></v-radio>
            <v-radio :label="$t('store.create_action.type_web')" value="web" data-test="add-store--type-web"></v-radio>
            <v-radio :label="$t('store.create_action.type_custom')" value="custom" data-test="add-store--type-custom"></v-radio>
          </v-radio-group>
        </v-flex>
        <div v-show="store.type === 'web'">
          <h4>{{ $t('store.create_action.web') }}</h4>
          <v-text-field
            :label="$t('store.create_action.url.en') + '*'"
            name="url_en"
            v-validate="{ required: store.type === 'web', url: store.type === 'web' }"
            :error-messages="errors.collect('url_en')"
            v-model="store.location.url.en"
            data-vv-name="url_en"
            :data-vv-as="$t('store.create_action.url.en')"
            data-test="add-store--url-en"
            required
          ></v-text-field>
          <v-text-field
            :label="$t('store.create_action.url.fr') + '*'"
            name="url_fr"
            v-validate="{ required: store.type === 'web', url: store.type === 'web' }"
            :error-messages="errors.collect('url_fr')"
            v-model="store.location.url.fr"
            data-vv-name="url_fr"
            :data-vv-as="$t('store.create_action.url.fr')"
            data-test="add-store--url-fr"
            required
          ></v-text-field>
        </div>
        <div v-show="store.type === 'place'">
          <h4>{{ $t('store.create_action.address.title') }}</h4>

          <search-address @updated="updateAddressModal"></search-address>

          <v-text-field
            name="street"
            ref="street"
            @paste="pasteParsedAddress"
            v-model="store.location.address.street"
            v-validate="{ required: store.type === 'place', max: 50 }"
            :label="$t(`store.create_action.address.street`) + '*'"
            :error-messages="errors.collect('street')"
            data-vv-name="street"
            :data-vv-as="$t(`store.create_action.address.street`)"
            maxlength="50"
            counter
            :hint="$t(`store.create_action.address.street_hint`)"
            data-test="add-store--street"
          ></v-text-field>
          <v-text-field
            name="city"
            v-model="store.location.address.city"
            v-validate="{ required: store.type === 'place', max: 50 }"
            :label="$t(`store.create_action.address.city`) + '*'"
            :error-messages="errors.collect('city')"
            data-vv-name="city"
            :data-vv-as="$t(`store.create_action.address.city`)"
            counter
            maxlength="50"
            data-test="add-store--city"
          ></v-text-field>
          <v-select
            name="state"
            v-model="store.location.address.state"
            v-validate="{ required: store.type === 'place' }"
            :error-messages="errors.collect('state')"
            data-vv-name="state"
            :data-vv-as="$t(`store.create_action.address.state`)"
            :items="stateOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`store.create_action.address.state`) + '*'"
            data-test="add-store--state"
          ></v-select>
          <v-select
            name="country"
            v-model="store.location.address.country"
            v-validate="{ required: store.type === 'place' }"
            :error-messages="errors.collect('country')"
            data-vv-name="country"
            :data-vv-as="$t(`store.create_action.address.country`)"
            :items="countryOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`store.create_action.address.country`) + '*'"
            data-test="add-store--country"
          ></v-select>
          <v-text-field
            name="zip"
            v-mask-zip
            v-model="store.location.address.zip"
            v-validate="{ required: store.type === 'place', regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
            :label="$t(`store.create_action.address.zip`) + '*'"
            :error-messages="errors.collect('zip')"
            data-vv-name="zip"
            :data-vv-as="$t(`store.create_action.address.zip`)"
            data-test="add-store--zip"
          ></v-text-field>
          <v-text-field
            name="plus_code"
            v-model="store.location.address.plus_code"
            v-validate="{ max: 20 }"
            :label="$t(`store.create_action.address.plus_code`)"
            :error-messages="errors.collect('plus_code')"
            data-vv-name="plus_code"
            :data-vv-as="$t(`store.create_action.address.plus_code`)"
            data-test="add-store--plus_code"
            maxlength="20"
          ></v-text-field>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import { parseAddress } from '@/utils/address-utils';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';

import Vue from 'vue';
import SearchAddress from '@/components/store/actions/SearchAddress.vue';
import _pickBy from 'lodash/pickBy';

const DEFAULT_EMPTY_ADDRESS = Object.freeze({
  street: '',
  city: '',
  zip: '',
  state: '',
  country: '',
  plus_code: '',
});
export default {
  name: 'create-store-action',
  components: { SearchAddress },
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      store: {
        location: { url: {}, address: { country: 'CA', state: 'QC' } },
        fuzzy_trx_enabled: false,
        type: null,
        complementary_info: {
          fr: '',
          en: '',
        },
      },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
    };
  },
  computed: {
    ...mapGetters('store', ['types', 'status']),
    typesTranslated() {
      return _map(this.types, (type) => ({
        text: this.$t(`store.types.${type}`),
        key: type,
      }));
    },
    statusTranslated() {
      return _map(this.status, (status) => ({
        text: this.$t(`store.status.${status}`),
        key: status,
      }));
    },
  },
  methods: {
    updateStoreAddress(address, overrideNotMutedFieldsWithDefaultValues = false) {
      const assignAddressValues = (values) => Object.entries(values).forEach(([key, value]) => Vue.set(this.store.location.address, key, value));
      const mutedValues = _pickBy(address, (item) => !_.isEmpty(item));

      if (overrideNotMutedFieldsWithDefaultValues) {
        assignAddressValues({
          ...DEFAULT_EMPTY_ADDRESS,
          ...mutedValues,
        });
      } else {
        assignAddressValues(mutedValues);
      }
    },
    pasteParsedAddress(event) {
      let pasteString = (event.clipboardData || window.clipboardData).getData('text');
      const address = parseAddress(pasteString);

      if (address) {
        event.preventDefault();
        this.updateStoreAddress(address);
      }
    },
    updateAddressModal({ address }) {
      this.updateStoreAddress(address, true);
    },
    clear() {
      this.store = { location: { url: {}, address: { country: 'CA', state: 'QC' } }, complementary_info: { fr: '', en: '' } };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('created');
      this.$router.push({ name: 'store_details', params: { storeId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'store/createStore',
            name: 'createStore',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      let payload;

      if (_get(this.store, 'complementary_info.fr') === '') {
        this.store.complementary_info.fr = null;
      }

      if (_get(this.store, 'complementary_info.en') === '') {
        this.store.complementary_info.en = null;
      }

      if (this.store.type === 'web') {
        payload = _omit(this.store, ['location.address']);
      }

      if (this.store.type === 'place') {
        payload = _omit(this.store, ['location.url']);

        if (_isEmpty(payload.location.address.plus_code)) {
          payload.location.address.plus_code = null;
        }
      }

      if (this.store.type === 'custom') {
        payload = _omit(this.store, ['location.address', 'location.url']);
      }

      if (!this.store.franchise_id) {
        payload.franchise_id = null;
      }

      return payload;
    },
  },
};
</script>
