import Vue from 'vue';

export default {
  async adjustWalletBalanceCredit({
    card_public_token,
    wallet_definition_id,
    amount,
    currency,
    reason,
    operation_group_id,
    load_operation_id,
    skip_webhook,
  }) {
    const payload = { amount: { value: amount, currency }, reason, operation_group_id, load_operation_id, skip_webhook };
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/wallets/${wallet_definition_id}/credit`,
      payload,
    );
    return response.data.data;
  },
  async adjustWalletBalanceDebit({
    card_public_token,
    wallet_definition_id,
    amount,
    currency,
    reason,
    operation_group_id,
    load_operation_id,
    skip_webhook,
  }) {
    const payload = { amount: { value: amount, currency }, reason, operation_group_id, load_operation_id, skip_webhook };
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/wallets/${wallet_definition_id}/debit`,
      payload,
    );
    return response.data.data;
  },
};
