<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('store.stores_table.id') }}</td>
        <td>{{ $t('store.stores_table.name') }}</td>
        <td>{{ $t('store.stores_table.type') }}</td>
        <td>{{ $t('store.stores_table.location') }}</td>
        <td v-if="!exclude.includes('business')">{{ $t('store.stores_table.owner') }}</td>
        <td v-if="!exclude.includes('branding')">{{ $t('store.stores_table.branding') }}</td>
        <td v-if="!exclude.includes('franchise')">{{ $t('store.stores_table.franchise') }}</td>
        <td v-if="!exclude.includes('destination')">{{ $t('store.stores_table.destination') }}</td>
        <td>{{ $t('store.stores_table.card_acceptors') }}</td>
        <td>{{ $t('store.stores_table.status') }}</td>
        <td>{{ $t('store.stores_table.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('store.stores_table.id') }}:</h5>
          <span>
            <router-link :to="{ name: 'store_details', params: { storeId: item.id } }">
              {{ item.id }}
            </router-link>
          </span>
        </td>
        <td>
          <h5>{{ $t('store.stores_table.name') }}:</h5>
          <span>{{ item.name }}</span>
        </td>
        <td>
          <h5>{{ $t('store.stores_table.type') }}:</h5>
          <span>{{ $t(`store.types.${item.type}`) }}</span>
        </td>
        <td>
          <h5>{{ $t('store.stores_table.location') }}:</h5>
          <span v-if="item.location && item.location.address">{{ fullAddress(item) }}</span>
          <span v-if="item.location && item.location.url">{{ item.location.url.fr + ', ' + item.location.url.en }}</span>
        </td>
        <td v-if="!exclude.includes('business')">
          <h5>{{ $t('store.stores_table.owner') }}:</h5>
          <span>
            <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ `${item.business.name} (${item.business_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td v-if="!exclude.includes('branding')">
          <h5>{{ $t('store.stores_table.branding') }}:</h5>
          <span>
            <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
              {{ `${item.branding.name} (${item.branding_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td v-if="!exclude.includes('franchise')">
          <h5>{{ $t('store.stores_table.franchise') }}:</h5>
          <span>
            <router-link v-if="item.franchise_id" :to="{ name: 'franchise_details', params: { franchiseId: item.franchise_id } }">
              {{ `${item.franchise.name} (${item.franchise_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td v-if="!exclude.includes('destination')">
          <h5>{{ $t('store.stores_table.destination') }}:</h5>
          <span>
            <router-link v-if="item.destination_id" :to="{ name: 'destination_details', params: { destinationId: item.destination_id } }">
              {{ `${item.destination.name} (${item.destination_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td align="center">
          <h5>{{ $t('store.stores_table.card_acceptors') }}:</h5>
          <span>{{ item.card_acceptors_count }}</span>
        </td>
        <td>
          <h5>{{ $t('store.stores_table.status') }}:</h5>
          <span><store-status-view :store-object="item"/></span>
        </td>
        <td>
          <h5>{{ $t('store.stores_table.created') }}:</h5>
          <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td v-if="!exclude.includes('action')">
          <link-secondary-action slot="labelTextLink" @click="deleteAction(item)">{{ $t('store.stores_table.delete_link') }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import StoreStatusView from '@/components/store/StoreStatusView';

export default {
  name: 'shared-stores-table',
  mixins: [actionErrorTrackable, security],
  components: { StoreStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
    exclude: {
      type: Array, // Acceptable values: ['business', 'branding', 'franchise', 'destination', 'action']
      required: false,
    },
  },
  methods: {
    fullAddress(item) {
      if (item.location) {
        return `${item.location.address.street}, ${item.location.address.city}, ${item.location.address.state}, ${item.location.address.country}, ${item.location.address.zip}`;
      }
      return '';
    },
    deleteAction(id) {
      this.$emit('deleteItem', id);
    },
  },
};
</script>
