import Vue from 'vue';

const service = {
  async generateManualEft(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/manual`, payload);

    return response.data;
  },

  async generateManualPad(cardProgramKey, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/eft/v1/card-programs/${cardProgramKey}/manual-debit`,
      payload,
    );

    return response.data;
  },

  async listBusinessEftItems(id, page, limit) {
    const pathWithPagingQueryParams = generatePagingPath(`/eft_v2/v1/businesses/${id}/eft-report-items`, page, limit);
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${pathWithPagingQueryParams}`);
    return response.data.data;
  },

  async listBusinessFeeItems(id, page, limit) {
    const pathWithPagingQueryParams = generatePagingPath(`/eft_v2/v1/businesses/${id}/manual-fee-items`, page, limit);
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${pathWithPagingQueryParams}`);
    return response.data.data;
  },

  async listBusinessFundingItems(id, page, limit) {
    const pathWithPagingQueryParams = generatePagingPath(`/eft_v2/v1/businesses/${id}/manual-funding-items`, page, limit);
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}${pathWithPagingQueryParams}`);
    return response.data.data;
  },
};

function generatePagingPath(startPath, page, limit) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
