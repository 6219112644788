<template>
  <card :title="$t('user.vouchers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.vouchers_section.voucher_count')"
        :value="accountVouchersItemCount.toString()"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('user.details.purses_section.action')}`">
        <router-link
          target="_blank"
          slot="labelTextLink"
          :to="{ name: 'vouchers_operations_dashboard', query: { user_id: account.id, timerange: '~' } }"
        >
          {{ $t('user.details.vouchers_section.view_vouchers_operations') }}
        </router-link>
      </label-text>
    </template>
    <template slot="content">
      <form @submit.prevent="submitListVouchersByAccount" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="user-vouchers_section--btn-search"
              :text="$t('user.details.vouchers_section.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <v-select
              name="partner_id"
              v-model="partner_id"
              :error-messages="errors.collect('partner_id')"
              data-vv-name="partner_id"
              :data-vv-as="$t('user.details.vouchers_section.partner_id')"
              :items="partnerOptions"
              item-text="name"
              item-value="value"
              clearable
              @change="onPartnerIdChange"
              :label="$t('user.details.vouchers_section.partner_id')"
              data-test="user-vouchers-partner_id"
            ></v-select>
            <v-select
              name="card_public_token"
              v-model="cardholder_id"
              :error-messages="errors.collect('card_public_token')"
              data-vv-name="card_public_token"
              :data-vv-as="$t('user.details.vouchers_section.card_public_token')"
              :items="cardItems"
              item-text="text"
              item-value="cardholder_id"
              clearable
              @change="onCardholderIdChange"
              :label="$t('user.details.vouchers_section.card_public_token')"
              data-test="user-vouchers-card_public_token"
            ></v-select>
            <v-select
              name="offer_id"
              v-model="offer_id"
              :error-messages="errors.collect('offer_id')"
              data-vv-name="offer_id"
              :data-vv-as="$t('user.details.vouchers_section.offer_id')"
              :items="offerItems"
              item-text="text"
              item-value="offer_id"
              clearable
              @change="onOfferIdChange"
              :label="$t('user.details.vouchers_section.offer_id')"
              data-test="user-vouchers-offer_id"
            ></v-select>
            <v-text-field
              ref="origin_order_id"
              name="origin_order_id"
              v-model="origin_order_id"
              :label="$t('user.details.vouchers_section.origin_order_id')"
              v-validate="{ max: 50 }"
              :error-messages="errors.collect('origin_order_id')"
              clearable
              @change="onOriginOrderIdChange"
              data-vv-name="origin_order_id"
              :data-vv-as="$t('user.details.vouchers_section.origin_order_id')"
              data-test="user-vouchers--origin_order_id"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </form>

      <user-vouchers-table v-if="dataReady && accountVouchers.length" :items="accountVouchers" :id="account.id"></user-vouchers-table>
      <label-none v-else>{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserVouchersTable from './UserVouchersTable';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'user-vouchers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserVouchersTable },
  data() {
    return {
      partner_id: '',
      cardholder_id: '',
      offer_id: '',
      origin_order_id: '',
      partnerOptions: [],
      cardOptions: [],
      offerOptions: [],
      page: 1,
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountVouchers',
      'accountVouchersPageCount',
      'accountVouchersPageNumber',
      'accountVouchersItemCount',
      'partnersForAccountVouchers',
      'cardholdersForAccountVouchers',
      'offersForAccountVouchers',
      'accountVouchersPartnerId',
      'accountVouchersCardholderId',
      'accountVouchersOfferId',
      'accountVouchersOriginOrderId',
    ]),
    ...mapGetters('ui', ['currentLocale']),
    cardItems() {
      return _.map(this.cardOptions, (it) => {
        const type = this.$t(`card.type.${it.card_type}`);
        it.text = `${it.public_token} (${type})`;
        return it;
      });
    },
    offerItems() {
      return _.map(this.offerOptions, (it) => {
        it.text = `${it.branding_name} (${it.offer_id})`;
        return it;
      });
    },
  },
  watch: {
    async account() {
      this.partner_id = this.accountVouchersPartnerId;
      this.cardholder_id = this.accountVouchersCardholderId;
      this.offer_id = this.accountVouchersOfferId;
      this.origin_order_id = this.accountVouchersOriginOrderId;
      await this.submitListVouchersByAccount(event, false, this.accountVouchersPageNumber);
      await this.fetchPartnerIds();
      await this.fetchCardInformation();
      await this.fetchOfferInformation();
    },
  },
  methods: {
    async onPartnerIdChange(res) {
      if (_.isEmpty(res)) {
        await this.executeAction({ action: 'account/clearAccountVouchersField' }, 'partner_id');
      }
    },
    async onCardholderIdChange(res) {
      if (_.isEmpty(res)) {
        await this.executeAction({ action: 'account/clearAccountVouchersField' }, 'cardholder_id');
      }
    },
    async onOfferIdChange(res) {
      if (_.isEmpty(res)) {
        await this.executeAction({ action: 'account/clearAccountVouchersField' }, 'offer_id');
      }
    },
    async onOriginOrderIdChange(res) {
      if (_.isEmpty(res)) {
        await this.executeAction({ action: 'account/clearAccountVouchersField' }, 'origin_order_id');
      }
    },
    async fetchPartnerIds() {
      await this.executeAction({ action: 'account/listPartnersForAccountVouchers' }, { id: this.account.id });
      this.partnerOptions = this.partnersForAccountVouchers;
    },
    async fetchCardInformation() {
      await this.executeAction({ action: 'account/listCardholdersForAccountVouchers' }, { id: this.account.id });
      this.cardOptions = this.cardholdersForAccountVouchers;
    },
    async fetchOfferInformation() {
      await this.executeAction({ action: 'account/listOffersForAccountVouchers' }, { id: this.account.id });
      this.offerOptions = this.offersForAccountVouchers;
    },
    async submitListVouchersByAccount(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'account/listVouchersByAccountId' },
          {
            id: this.account.id,
            partner_id: this.partner_id,
            cardholder_id: this.cardholder_id,
            offer_id: this.offer_id,
            origin_order_id: this.origin_order_id,
            page,
          },
        );
        this.dataReady = true;
      }
    },
  },
};
</script>
