import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;
const destinationStoresPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    destinations: [],
    destination: { address: {}, coordinates: {} },
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
    },
    destinationStores: [],
    destinationStatsStoreCount: null,
    destinationStoresPageNumber: defaultPage,
    destinationStoresPageCount: 0,
    destinationStoresItemCount: 0,
  },
  getters: {
    destinations: (state) => state.destinations,
    destination: (state) => state.destination,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    destinationStores: (state) => state.destinationStores,
    destinationStoresPageCount: (state) => state.destinationStoresPageCount,
    destinationStoresItemCount: (state) => state.destinationStoresItemCount,
    destinationStoresPageNumber: (state) => state.destinationStoresPageNumber,
    destinationStatsStoreCount: (state) => state.destinationStatsStoreCount,
  },
  mutations: {
    destinations(state, destinations) {
      state.destinations = destinations;
    },
    destination(state, destination) {
      if (!destination) {
        destination = { address: {}, coordinates: {} };
      }
      state.destination = destination;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    destinationStatsStoreCount(state, destinationStatsStoreCount) {
      state.destinationStatsStoreCount = destinationStatsStoreCount;
    },
    destinationStores(state, destinationStores) {
      state.destinationStores = destinationStores;
    },
    destinationStoresPageCount(state, pageCount) {
      state.destinationStoresPageCount = pageCount;
    },
    destinationStoresItemCount(state, itemCount) {
      state.destinationStoresItemCount = itemCount;
    },
    destinationStoresPageNumber(state, page) {
      state.destinationStoresPageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const destinationService = Vue.prototype.$services.destination;

      return destinationService.searchDestinations(page, limit, keyword);
    },
    async clearSearchDestinations({ commit }) {
      commit('destinations', []);
    },
    async createDestination({}, params) {
      const destinationService = Vue.prototype.$services.destination;
      return destinationService.createDestination(params);
    },
    async listDestinations({ state, commit }, { keyword, page, limit } = {}) {
      const destinationService = Vue.prototype.$services.destination;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const listResults = await destinationService.listDestinations(theKeyword, thePage, theLimit);

      commit('destinations', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
    },
    async getDestination({ commit }, id) {
      const destinationService = Vue.prototype.$services.destination;
      const destination = await destinationService.getDestination(id);

      commit('destination', destination);
      return destination;
    },
    async getDestinationStatistics({ commit }, id) {
      const destinationService = Vue.prototype.$services.destination;
      const destinationStoreStatistics = await destinationService.getDestinationStatistics(id);
      commit('destinationStatsStoreCount', destinationStoreStatistics.store_count);
    },
    async updateDestination({ dispatch }, params) {
      const destinationService = Vue.prototype.$services.destination;
      const { id, ...payload } = params;
      const result = await destinationService.updateDestination(id, payload);
      await dispatch('getDestination', id);
      return result;
    },
    async addStoreToDestination({ dispatch }, { destination_id, store_id }) {
      const destinationService = Vue.prototype.$services.destination;
      const result = await destinationService.addStoreToDestination(destination_id, store_id);
      await dispatch('getDestination', destination_id);
      return result;
    },
    async updateDestinationCoordinates({ dispatch }, params) {
      const destinationService = Vue.prototype.$services.destination;
      const { id, ...payload } = params;
      const result = await destinationService.updateDestinationCoordinates(id, payload);
      await dispatch('getDestination', id);
      return result;
    },
    async regenerateDestinationCoordinates({ dispatch }, id) {
      const destinationService = Vue.prototype.$services.destination;
      const result = await destinationService.regenerateDestinationCoordinates(id);
      await dispatch('getDestination', id);
      return result;
    },
    async deleteStoreDestination({ dispatch }, { store_destination_id, destination_id }) {
      const destinationService = Vue.prototype.$services.destination;

      await destinationService.deleteStoreDestination(store_destination_id);
      await dispatch('getDestination', destination_id);
    },
    async listDestinationStores({ commit }, { id, page }) {
      const destinationService = Vue.prototype.$services.destination;
      const thePage = page || defaultPage;
      const result = await destinationService.listDestinationStores(id, 1, destinationStoresPaginationLimit);
      const destinationStores = result.items;

      commit('destinationStores', destinationStores);
      commit('destinationStoresPageNumber', thePage);
      commit('destinationStoresPageCount', result.page_count);
      commit('destinationStoresItemCount', result.item_count);
    },
    async changeDestinationStoresPage({ commit }, { id, page }) {
      commit('destinationStoresPageNumber', page);

      const destinationService = Vue.prototype.$services.destination;
      const limit = destinationStoresPaginationLimit;

      const destinationStoresResult = await destinationService.listDestinationStores(id, page, limit);

      commit('destinationStoresPageCount', destinationStoresResult.page_count);
      commit('destinationStores', destinationStoresResult.items);
    },
  },
};
