<template>
  <card :title="$t('destination.stores.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('destination.stores.count')}`" :value="destinationStoresItemCount || '0'"></label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text class="text-sm-center" :label="$t('destination.stores.action')">
        <link-secondary-action slot="labelTextLink" @click="openAddDestinationStoreModal()">
          {{ $t('destination.stores.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddDestinationStore" close>
        <add-store-to-destination-action
          :destination="destination"
          @success="addDestinationStoreCompleted"
          @close="addDestinationStoreCanceled"
        ></add-store-to-destination-action>
      </modal-action>

      <modal-confirm
        v-if="storeDestinationToDelete"
        :show-modal="showDeleteStoreDestinationModal"
        :title="$t(`destination.stores.delete_action.title`)"
        context="deleteDestinationStore"
        :text="
          $t(`destination.stores.delete_action.text`, {
            storeDestinationName: `${storeDestinationToDelete.name} - (${storeDestinationToDelete.id})`,
          })
        "
        :cancel_text="`destination.stores.delete_action.button_cancel`"
        :submit_text="`destination.stores.delete_action.button_submit`"
        :submit_working="working"
        @submit="deleteDestinationStore"
        @close="deleteDestinationStoreCanceled"
      ></modal-confirm>

      <destination-stores-table
        v-if="destinationStores.length"
        :items="destinationStores"
        :destination-id="destination.id"
        @destination-store-delete-action="openDeleteDestinationStoreModal"
      ></destination-stores-table>
      <label-none v-if="destinationStores.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import DestinationStoresTable from './DestinationStoresTable.vue';
import AddStoreToDestinationAction from '@/components/destination/actions/AddStoreToDestinationAction.vue';

export default {
  name: 'destination-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { AddStoreToDestinationAction, DestinationStoresTable },
  data() {
    return {
      showAddDestinationStore: false,

      storeDestinationToDelete: null,
      showDeleteStoreDestinationModal: false,

      pagination: 1,
    };
  },
  watch: {
    async destination() {
      return this.executeAction({ action: 'destination/listDestinationStores' }, { id: this.destination.id, page: this.destinationStoresPageNumber });
    },
    destinationStoresPageNumber() {
      this.pagination = this.destinationStoresPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'destination/changeDestinationStoresPage',
          success: this.success,
        },
        {
          id: this.destination.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    addDestinationStoreCompleted() {
      this.showAddDestinationStore = false;
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'destination.stores.add_action.success' });
    },
    addDestinationStoreCanceled() {
      this.showAddDestinationStore = false;
    },

    openAddDestinationStoreModal() {
      this.showAddDestinationStore = true;
    },
    openDeleteDestinationStoreModal(store) {
      this.showDeleteStoreDestinationModal = true;
      this.storeDestinationToDelete = store;
    },
    deleteDestinationStoreCompleted() {
      this.showDeleteStoreDestinationModal = false;
      this.storeDestinationToDelete = null;
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'destination.stores.delete_action.success' });
    },
    deleteDestinationStoreCanceled() {
      this.showDeleteStoreDestinationModal = false;
      this.storeDestinationToDelete = null;
    },

    async deleteDestinationStore() {
      await this.executeAction(
        {
          action: 'destination/deleteStoreDestination',
          name: 'deleteDestinationStore',
          success: this.deleteDestinationStoreCompleted,
        },
        { store_destination_id: this.storeDestinationToDelete.store_destination_id, destination_id: this.storeDestinationToDelete.destination_id },
      );
    },
  },
  computed: {
    ...mapGetters('destination', ['destination', 'destinationStores', 'destinationStoresPageNumber', 'destinationStoresItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
