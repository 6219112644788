<template>
  <div :aria-label="ariaLabel">
    <v-card flat row v-for="(wallet, index) in items" :key="index" class="wallets-info">
      <v-layout row class="wallets-info--header-zone">
        <v-icon v-if="openAndCannotBeUsed(wallet)" color="error" class="wallets-info--lock-open">far fa-lock-open</v-icon>
        <v-icon v-else-if="wallet.wallet_definition_id === 'open'" color="primary" class="wallets-info--lock-open">far fa-lock-open</v-icon>
        <v-icon v-else color="primary" class="wallets-info--lock-closed">far fa-lock</v-icon>
        <h4 :class="{ 'wallets-info--wallet-title': true, 'open-cannot-use': openAndCannotBeUsed(wallet) }">
          <link-secondary-route
            :can-access="enableWalletDefinitionLink(wallet)"
            :route-name="'wallet_definition_details'"
            :route-params="{ walletDefinitionId: wallet.wallet_definition_id }"
            >{{
              wallet.wallet_definition_id === 'open' ? 'open' : (wallet.wallet_definition_name || 'n/a') + ' (' + wallet.wallet_definition_id + ')'
            }}</link-secondary-route
          >
        </h4>
      </v-layout>
      <v-layout row wrap class="wallets-info--content-zone">
        <v-layout column>
          <v-layout row v-if="openAndCannotBeUsed(wallet)">
            <p class="wallets-info--wallet-open-cannot-use">{{ $t('card.limits_and_balances.cannot_use') }}</p>
          </v-layout>
          <v-layout row>
            <label-text
              :label="$t('card.limits_and_balances.available')"
              :value="wallet.available_balance | currency"
              :class-array="['wallets-info--wallet-available-balance']"
            ></label-text>
            <label-text :label="$t('card.limits_and_balances.hold')" :value="walletHoldBalance(wallet) | currency"></label-text>
          </v-layout>
          <v-layout column class="wallets-info--links">
            <router-link
              target="_blank"
              :to="{
                name: 'debits_credits_dashboard',
                query: { cardholder_id: card.cardholder.id, wallet_definition_id: wallet.wallet_definition_id, timerange: '~' },
              }"
            >
              {{ $t('card.limits_and_balances.debits_credits_link') }}
            </router-link>
            <!-- NOTE (SG) !wallet.wallet_definition_system_managed old rules replace by isDev -->
            <a
              v-if="isAgentInternal() && isDev() && wallet.wallet_definition_id !== 'open'"
              class="adjust-wallet-balance"
              @click="openAdjustWalletBalanceModal(wallet)"
              >{{ $t('card.limits_and_balances.adjust_wallet_balance') }}</a
            >
            <a
              v-if="isAgentInternal() && isDev() && wallet.wallet_definition_id === 'open'"
              class="adjust-wallet-balance"
              @click="openAdjustWalletBalanceModal(wallet)"
              >{{ $t('card.limits_and_balances.adjust_wallet_balance') }}</a
            >
            <a
              v-if="classicProgram && isDev() && isAgentInternal() && wallet.wallet_definition_id === 'open'"
              role="button"
              @click="showModalLoadWallet"
              id="wallets-info--load-open-wallet"
              >{{ $t('card.limits_and_balances.load_open_wallet') }}</a
            >
          </v-layout>
        </v-layout>
        <v-layout column>
          <v-layout row>
            <div v-if="wallet.wallet_definition_partner_id" class="balances-content-divider--vertical hidden-sm-and-down">
              <v-divider vertical></v-divider>
            </div>
            <label-text
              v-if="wallet.wallet_definition_partner_id"
              :label="$t('card.limits_and_balances.partner')"
              :value="wallet.wallet_definition_partner_id"
            ></label-text>
          </v-layout>
        </v-layout>

        <modal-action :show-modal="showLoadWalletModal">
          <load-open-wallet-action :public_token="card.public_token" @close="closeModalLoadWallet" @action="reloadPage"></load-open-wallet-action>
        </modal-action>
      </v-layout>
    </v-card>

    <modal-action :show-modal="showAdjustWalletBalanceModal">
      <adjust-wallet-balance-action
        :card-public-token="card.public_token"
        :wallet="currentWallet"
        @close="closeAdjustWalletBalanceModal"
        @action="closeAdjustWalletBalanceModal"
      ></adjust-wallet-balance-action>
    </modal-action>
  </div>
</template>

<script>
import _ from 'lodash';
import { actionErrorTrackable, security } from '@/mixins';
import LoadOpenWalletAction from '../actions/LoadOpenWalletAction';
import AdjustWalletBalanceAction from '../actions/AdjustWalletBalanceAction';

export default {
  name: 'wallets',
  mixins: [actionErrorTrackable, security],
  components: { LoadOpenWalletAction, AdjustWalletBalanceAction },
  props: {
    items: {
      type: Array,
    },
    card: {
      type: Object,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showLoadWalletModal: false,
      showAdjustWalletBalanceModal: false,
      currentWallet: {},
    };
  },
  computed: {
    classicProgram() {
      return this.card && this.card.card_program_type === 'gpr';
    },
  },
  methods: {
    reloadPage() {
      this.showLoadWalletModal = false;
    },
    openAndCannotBeUsed(wallet) {
      return wallet.wallet_definition_id === 'open' && this.card.card_program_type !== 'gpr';
    },
    enableWalletDefinitionLink(wallet) {
      return this.canViewWalletDefinition() && wallet.wallet_definition_id !== 'open';
    },
    walletHoldBalance(wallet) {
      return _.round(wallet.actual_balance - wallet.available_balance, 2);
    },
    openAdjustWalletBalanceModal(wallet) {
      this.currentWallet = wallet;
      this.showAdjustWalletBalanceModal = true;
    },
    closeAdjustWalletBalanceModal() {
      this.showAdjustWalletBalanceModal = false;
      this.currentWallet = {};
      this.$emit('refresh_wallets');
    },
    showModalLoadWallet() {
      this.showLoadWalletModal = true;
    },
    closeModalLoadWallet() {
      this.showLoadWalletModal = false;
    },
  },
};
</script>
