<template>
  <card :title="`${$t('business.eft_items.data_section.title')}`" icon="">
    <template slot="content">
      <label-none v-if="dataReady && businessEftItems.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
      <business-eft-items-table v-if="dataReady && businessEftItems.length > 0" :items="businessEftItems"></business-eft-items-table>
      <div class="text-xs-center">
        <v-pagination
          v-if="businessEftItemsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="businessEftItemsPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import BusinessEftItemsTable from './BusinessEftItemsTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-eft-items',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { BusinessEftItemsTable },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataReady: false,
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBusinessEftItems();
    this.pagination = this.businessEftItemsPageNumber;
    this.dataReady = true;
  },
  computed: {
    ...mapGetters('eft', ['businessEftItems', 'businessEftItemsPageCount', 'businessEftItemsPageNumber']),
  },
  methods: {
    async fetchBusinessEftItems(page) {
      const pageNumber = page || this.businessEftItemsPageNumber;
      await this.executeAction(
        { action: 'eft/listBusinessEftItems' },
        {
          id: this.businessId,
          page: pageNumber,
        },
      );
    },
  },
  watch: {
    businessEftItemsPageNumber() {
      this.pagination = this.businessEftItemsPageNumber;
    },
    async pagination(newValue) {
      return this.fetchBusinessEftItems(newValue);
    },
  },
};
</script>
