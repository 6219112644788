import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('security', ['currentUser', 'hasPermission']),
  },
  methods: {
    canAccessBeta() {
      return this.hasPermission('beta');
    },
    isDev() {
      return this.hasPermission('dev');
    },
    canManageInstantCard() {
      return this.hasPermission('manage_instant_card');
    },
    canApproveInstantCardBatch() {
      return this.hasPermission('approve_instant_card_batch');
    },
    canViewApplication() {
      return this.hasPermission('view_application');
    },
    canManageApplication() {
      return this.hasPermission('manage_application');
    },
    canCommentApplication() {
      return this.hasPermission('comment_application');
    },
    canUploadDocumentApplication() {
      return this.hasPermission('upload_document_application');
    },
    canViewCard() {
      return this.hasPermission('view_card');
    },
    canManageCard() {
      return this.hasPermission('manage_card');
    },
    canCommentCard() {
      return this.hasPermission('comment_card');
    },
    canUploadDocumentCard() {
      return this.hasPermission('upload_document_card');
    },
    canViewWalletDefinition() {
      return this.hasPermission('view_wallet_definition');
    },
    canManageWalletDefinition() {
      return true;
    },
    canManageAmlProgram() {
      return this.hasPermission('manage_aml_program');
    },
    isAgentInternal() {
      return this.hasPermission('internal');
    },
    canViewTask() {
      return this.hasPermission('view_task');
    },
    canViewPublisher() {
      return this.hasPermission('manage_merchant');
    },
    canViewOffer() {
      return this.hasPermission('view_offer');
    },
    canManageOffer() {
      return this.hasPermission('manage_offer');
    },
    isAccounting() {
      return this.hasPermission('accounting');
    },
  },
};
