<template>
  <div>
    <table class="responsive-table" id="offer-vouchers-table">
      <thead>
        <tr>
          <td>{{ $t('user.vouchers_table.fields.id') }}</td>
          <td>{{ $t('user.vouchers_table.fields.offer_id') }}</td>
          <td>{{ $t('user.vouchers_table.fields.publisher') }}</td>
          <td>{{ $t('user.vouchers_table.fields.partner_id') }}</td>
          <td>{{ $t('user.vouchers_table.fields.cardholder') }}</td>
          <td align="right">{{ $t('user.vouchers_table.fields.payment') }}</td>
          <td align="right">{{ $t('user.vouchers_table.fields.bonus') }}</td>
          <td align="right">{{ $t('user.vouchers_table.fields.received') }}</td>
          <td align="right">{{ $t('user.vouchers_table.fields.remaining') }}</td>
          <td>{{ $t('user.vouchers_table.fields.created') }}</td>
          <td>{{ $t('user.vouchers_table.fields.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('user.vouchers_table.fields.id') }}:</h5>
            <router-link v-if="item.id" :to="{ name: 'voucher_details', params: { voucherId: item.id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.offer_id') }}:</h5>
            <router-link v-if="item.offer_id" :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
              {{ item.offer_branding.name }} ({{ item.offer_id }})
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.publisher') }}:</h5>
            <router-link :to="{ name: 'closed_loop_details', params: { publishersId: item.publisher_id } }">
              <publishers-name :class="{ 'info-not-available': !item.publisher_id }" :id="item.publisher_id"></publishers-name>
            </router-link>
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.partner_id') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.cardholder') }}:</h5>
            <cardholder-current-card v-if="item.cardholder_id" :cardholder-id="item.cardholder_id" />
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="right">
            <h5>{{ $t('user.vouchers_table.fields.payment') }}:</h5>
            {{ item.payment_amount | currency | dollarSignI18n }}
          </td>
          <td align="right">
            <h5>{{ $t('user.vouchers_table.fields.bonus') }}:</h5>
            {{ item.bonus_amount | currency | dollarSignI18n }}
          </td>
          <td align="right">
            <h5>{{ $t('user.vouchers_table.fields.received') }}:</h5>
            {{ (item.payment_amount + item.bonus_amount) | currency | dollarSignI18n }}
          </td>
          <td align="right">
            <h5>{{ $t('user.vouchers_table.fields.remaining') }}:</h5>
            {{ item.remaining_amount | currency | dollarSignI18n }}
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('user.vouchers_table.fields.status') }}:</h5>
            <voucher-status-view slot="labelTextLink" :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="accountVouchersPageCount > 1" v-model="pagination" :length="accountVouchersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import PublishersName from '@/components/publishers/PublishersName';
import VoucherStatusView from '@/components/voucher/VoucherStatusView';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-vouchers-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { PublishersName, VoucherStatusView },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.accountVouchersPageNumber;
  },
  methods: {
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (card) {
        this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
      }
    },
  },
  computed: {
    ...mapGetters('account', [
      'accountVouchersPartnerId',
      'accountVouchersCardholderId',
      'accountVouchersOfferId',
      'accountVouchersOriginOrderId',
      'accountVouchersPageNumber',
      'accountVouchersPageCount',
    ]),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/listVouchersByAccountId',
        },
        {
          id: this.id,
          partner_id: this.accountVouchersPartnerId,
          cardholder_id: this.accountVouchersCardholderId,
          offer_id: this.accountVouchersOfferId,
          origin_order_id: this.accountVouchersOriginOrderId,
          page: newValue,
        },
      );
    },
  },
};
</script>
