import axios from 'axios';
import errorTypes from '@/utils/error/error-types';

function isUnauthorized(status) {
  return status === 401;
}
function isForbidden(status) {
  return status === 403;
}
function isMethodNotAllowed(status) {
  return status === 405;
}

function isValidationError(status, code) {
  return status === 400 && code === 'validation.error';
}

function isNotFound(status) {
  return status === 404;
}

function isServerError(status) {
  return status >= 500;
}

function isBlobErrorResponseType(error) {
  return _.get(error, 'config.responseType') === 'blob';
}

export default ({ Vue, router, store }) => {
  Vue.prototype.$axios = axios;

  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.validateStatus = (status) => status >= 200 && status < 300;
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log(error); // eslint-disable-line no-console
      if (error.response) {
        let statusCode = error.response.status;

        if (isBlobErrorResponseType(error)) {
          // NOTE (SG) If error on some download binary data endpoint we presume we will receive JSON response and not a binary content for the error detail.
          error.response.data = JSON.parse(await error.response.data.text());
        }

        if (!isBlobErrorResponseType(error) && isNotFound(statusCode) && error.response.config.method === 'get') {
          return error.response;
        }

        let errorCode = error.response.data.error.code;

        if (isUnauthorized(statusCode) || isForbidden(statusCode)) {
          await store.dispatch('security/logout');
          router.push({ name: 'login' });
          throw new errorTypes.SystemError(error.response.status, error.response.data);
        }

        if (isMethodNotAllowed(statusCode) || isValidationError(statusCode, errorCode) || isServerError(statusCode)) {
          throw new errorTypes.SystemError(error.response.status, error.response.data);
        }

        throw new errorTypes.HttpError(error.response.status, error.response.data);
      }

      throw error;
    },
  );
};
