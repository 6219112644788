<template>
  <card :title="$t('store.details.store_information_section.title')" icon="fal fa-store" id="store-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('store.details.store_information_section.id')}`" :value="store.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ store.id }}
            <button class="clipboard" v-clipboard:copy="store.id ? store.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.store_information_section.name')}`" :value="store.name"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.store_information_section.status')}`" value="">
        <store-status-view slot="labelTextLink" class="font-weight-bold" :store-object="store"></store-status-view>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.store_information_section.system_status.pad')}`" :value="store.system_status.pad">
        <ready-status-view slot="labelTextLink" :status="store.system_status.pad" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.store_information_section.system_status.display')}`" :value="store.system_status.display">
        <ready-status-view slot="labelTextLink" :status="store.system_status.display" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.store_information_section.system_status.transactional')}`" :value="store.system_status.transactional">
        <ready-status-view slot="labelTextLink" :status="store.system_status.transactional" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('store.details.store_information_section.created')}`"
        :value="store.creation_date | formatShortDateInverted | orNotAvailable"
      ></label-text>
      <div v-if="showUpdateStoreFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showUpdateStoreFeature()" :label="`${$t('store.details.store_information_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUpdateStoreModal">
          {{ $t('store.details.store_information_section.update_store') }}</link-secondary-action
        >
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdateStoreModal"
        ><update-store-action
          v-if="showUpdateStoreModal"
          :store="store"
          @close="closeUpdateStoreModal"
          @updated="updateStoreSuccess"
        ></update-store-action
      ></modal-action>
      <v-layout row>
        <v-flex xs3
          ><label-text :label="$t(`store.details.store_information_section.type`)" :value="$t(`store.types.${store.type}`)"></label-text
        ></v-flex>
        <v-flex xs3 v-if="store.complementary_info">
          <label-text
            :label="$t(`store.details.store_information_section.complementary_info_label.fr`)"
            :value="store.complementary_info.fr | truncateEllipsis(128)"
            :title="store.complementary_info.fr"
          />
        </v-flex>
        <v-flex xs3 v-if="store.complementary_info">
          <label-text
            :label="$t(`store.details.store_information_section.complementary_info_label.en`)"
            :value="store.complementary_info.en | truncateEllipsis(128)"
            :title="store.complementary_info.en"
          />
        </v-flex> </v-layout
      ><v-layout row>
        <v-flex xs3>
          <td>
            <h5>{{ $t('store.details.store_information_section.franchise') }}</h5>
            <span>
              <router-link v-if="store.franchise_id" :to="{ name: 'franchise_details', params: { franchiseId: store.franchise_id } }">
                {{ `${store.franchise.name} (${store.franchise_id})` }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateStoreAction from '../actions/UpdateStoreAction';
import StoreStatusView from '../StoreStatusView';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import _ from 'lodash';

export default {
  name: 'store-information',
  components: { UpdateStoreAction, StoreStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showUpdateStoreModal: false,
    };
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('ui', ['currentLocale']),
    fullAddress() {
      if (this.store.location && !_.isEmpty(this.store.location.address)) {
        return `${this.store.location.address.street}, ${this.store.location.address.city}, ${this.store.location.address.state}, ${this.store.location.address.country}, ${this.store.location.address.zip}`;
      }
      return '';
    },
  },
  methods: {
    _get,
    openUpdateStoreModal() {
      this.showUpdateStoreModal = true;
    },
    closeUpdateStoreModal() {
      this.showUpdateStoreModal = false;
    },
    async updateStoreSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.store_information_section.update_action.success' });
      this.closeUpdateStoreModal();
    },
    showUpdateStoreFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
  },
};
</script>

<style scoped lang="stylus">
.complementary-info-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
