<template>
  <card-modal
    icon=""
    :title="$t('destination.stores.add_action.title')"
    context="addStoreToDestination"
    @submit="submit"
    @cancel="cancel"
    cancel_text="destination.stores.add_action.button_cancel"
    submit_text="destination.stores.add_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('destination.stores.add_action.destination')" :text="destinationInfo"></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="storeId"
          :label="$t('destination.stores.add_action.store_label')"
          v-validate="'required'"
          :error-messages="errors.collect('store')"
          name="publisher"
          data-vv-name="store"
          data-vv-as="store"
          search-action="store/autocompleteSearch"
          data-test="add-destination-store"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'add-destination-store-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    destination: {
      type: Object,
    },
  },
  data() {
    return {
      storeId: null,
    };
  },
  computed: {
    destinationInfo() {
      return `${this.destination.name} (${this.destination.id})`;
    },
  },
  methods: {
    clear() {
      this.storeId = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.storeId) {
          const payload = { store_id: this.storeId, destination_id: this.destination.id };

          await this.executeAction(
            {
              action: 'destination/addStoreToDestination',
              name: 'addStoreToDestination',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
