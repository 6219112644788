import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';

export default {
  namespaced: true,
  state: {},

  getters: {},
  mutations: {},
  actions: {
    async ajustWallet(
      { dispatch },
      {
        operation_type,
        card_public_token,
        wallet_definition_id,
        amount,
        currency = 'CAD',
        reason,
        operation_group_id,
        skip_webhook,
        load_operation_id,
      },
    ) {
      validateOperationTypes(operation_type);
      const walletService = Vue.prototype.$services.wallet;

      const parameters = { card_public_token, wallet_definition_id, amount, currency, reason, skip_webhook };
      if (operation_group_id && operation_group_id !== '') {
        parameters.operation_group_id = operation_group_id;
      }
      if (load_operation_id && load_operation_id !== '') {
        parameters.load_operation_id = load_operation_id;
      }
      operation_type === 'debit'
        ? await walletService.adjustWalletBalanceDebit(parameters)
        : await walletService.adjustWalletBalanceCredit(parameters);
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('card/getCard', card_public_token);

      function validateOperationTypes(type) {
        const validOperationTypes = ['credit', 'debit'];
        if (!type || !validOperationTypes.includes(type)) {
          throw new Error(`OperationType "${type}" is not valid`);
        }
      }
    },
  },
};
