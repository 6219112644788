import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 10;

export default {
  namespaced: true,
  state: {
    businessEftItems: [],
    businessFeeItems: [],
    businessFundingItems: [],
    search: {
      pageNumber: 1,
      pageCount: 0,
    },
    businessEftItemsPageNumber: defaultPage,
    businessEftItemsPageCount: 0,
    businessFeeItemsPageNumber: defaultPage,
    businessFeeItemsPageCount: 0,
    businessFundingItemsPageNumber: defaultPage,
    businessFundingItemsPageCount: 0,
  },
  mutations: {
    eftItems(state, businessEftItems) {
      state.businessEftItems = businessEftItems;
    },
    eftItemsPageNumber(state, page) {
      state.businessEftItemsPageNumber = page;
    },
    eftItemsPageCount(state, pageCount) {
      state.businessEftItemsPageCount = pageCount;
    },
    feeItems(state, businessFeeItems) {
      state.businessFeeItems = businessFeeItems;
    },
    feeItemsPageNumber(state, page) {
      state.businessFeeItemsPageNumber = page;
    },
    feeItemsPageCount(state, pageCount) {
      state.businessFeeItemsPageCount = pageCount;
    },
    fundingItems(state, businessFundingItems) {
      state.businessFundingItems = businessFundingItems;
    },
    fundingItemsPageNumber(state, page) {
      state.businessFundingItemsPageNumber = page;
    },
    fundingItemsPageCount(state, pageCount) {
      state.businessFundingItemsPageCount = pageCount;
    },
  },
  getters: {
    businessEftItems: (state) => state.businessEftItems,
    businessEftItemsPageNumber: (state) => state.businessEftItemsPageNumber,
    businessEftItemsPageCount: (state) => state.businessEftItemsPageCount,
    businessFeeItems: (state) => state.businessFeeItems,
    businessFeeItemsPageNumber: (state) => state.businessFeeItemsPageNumber,
    businessFeeItemsPageCount: (state) => state.businessFeeItemsPageCount,
    businessFundingItems: (state) => state.businessFundingItems,
    businessFundingItemsPageNumber: (state) => state.businessFundingItemsPageNumber,
    businessFundingItemsPageCount: (state) => state.businessFundingItemsPageCount,
  },
  actions: {
    async listBusinessEftItems({ commit }, { id, page, limit }) {
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const eftService = Vue.prototype.$services.eft;
      const eftItems = await eftService.listBusinessEftItems(id, thePage, theLimit);

      commit('eftItems', eftItems.items);
      commit('eftItemsPageNumber', thePage);
      commit('eftItemsPageCount', eftItems.page_count);
    },
    async listBusinessFeeItems({ commit }, { id, page, limit }) {
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const eftService = Vue.prototype.$services.eft;
      const feeItems = await eftService.listBusinessFeeItems(id, thePage, theLimit);

      commit('feeItems', feeItems.items);
      commit('feeItemsPageNumber', thePage);
      commit('feeItemsPageCount', feeItems.page_count);
    },
    async listBusinessFundingItems({ commit }, { id, page, limit }) {
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const eftService = Vue.prototype.$services.eft;
      const fundingItems = await eftService.listBusinessFundingItems(id, thePage, theLimit);

      commit('fundingItems', fundingItems.items);
      commit('fundingItemsPageNumber', thePage);
      commit('fundingItemsPageCount', fundingItems.page_count);
    },
    async generateManualEft({ commit, dispatch }, payload) {
      const eftService = Vue.prototype.$services.eft;

      const result = await eftService.generateManualEft(payload);

      await dispatch('listBusinessFeeItems', { id: payload.business_id, page: 1 });
      await dispatch('listBusinessFundingItems', { id: payload.business_id, page: 1 });

      return result;
    },
    async generateManualPad({ commit }, { cardProgramKey, payload }) {
      const eftService = Vue.prototype.$services.eft;

      return eftService.generateManualPad(cardProgramKey, payload);
    },
  },
};
