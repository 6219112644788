<template>
  <card-modal
    icon=""
    :title="$t('store.destinations.add_action.title')"
    context="addDestinationToStore"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.destinations.add_action.button_cancel"
    submit_text="store.destinations.add_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('store.destinations.add_action.store')" :text="storeInfo"></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="destinationToAdd"
          :label="$t('store.destinations.add_action.destination')"
          v-validate="'required'"
          :error-messages="errors.collect('publisher')"
          :alternate-text="getLabel"
          name="publisher"
          data-vv-name="publisher"
          data-vv-as="publisher"
          search-action="destination/autocompleteSearch"
          data-test="add-store-publisher"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'add-store-destination-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    store: {
      type: Object,
    },
  },
  data() {
    return {
      destinationToAdd: null,
    };
  },
  computed: {
    storeInfo() {
      return `${this.store.name} (${this.store.id})`;
    },
  },
  methods: {
    getLabel(destination) {
      return `${destination.partner_id} - ${destination.name}`;
    },
    clear() {
      this.destinationToAdd = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('cancel');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.destinationToAdd) {
          const payload = { store_id: this.store.id, destination_id: this.destinationToAdd };

          await this.executeAction(
            {
              action: 'store/addDestinationToStore',
              name: 'addDestinationToStore',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
