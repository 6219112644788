<template>
  <card :title="$t('store.destinations.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('store.destinations.count')}`" :value="storeDestinationsItemCount || '0'"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.destinations.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="showAddDestinationToStoreModal">
          {{ $t('store.destinations.add_destination') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addDestinationToStoreShown">
        <add-destination-to-store-action
          :store="store"
          @cancel="addDestinationToStoreCanceled"
          @success="addDestinationToStoreCompleted"
        ></add-destination-to-store-action
      ></modal-action>

      <modal-confirm
        v-if="storeDestinationToDelete"
        :show-modal="showDeleteStoreDestinationModal"
        :title="$t(`store.destinations.delete_action.title`)"
        context="deleteStoreDestination"
        :text="
          $t(`store.destinations.delete_action.text`, {
            storeDestinationName: `${storeDestinationToDelete.name} - (${storeDestinationToDelete.id})`,
          })
        "
        :cancel_text="`store.destinations.delete_action.button_cancel`"
        :submit_text="`store.destinations.delete_action.button_submit`"
        :submit_working="working"
        @submit="deleteStoreDestination"
        @close="closeDeleteStoreDestinationModal"
      ></modal-confirm>
      <store-destinations-table
        v-if="storeDestinations.length"
        @store-destination-delete-action="openDeleteStoreDestinationModal"
        :items="storeDestinations"
      >
      </store-destinations-table>
      <label-none v-if="storeDestinations.length === 0">{{ $t('system.none') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="storeDestinationsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="storeDestinationsPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import StoreDestinationsTable from './StoreDestinationsTable';
import { mapGetters } from 'vuex';
import AddDestinationToStoreAction from '../actions/AddDestinationToStoreAction';

export default {
  name: 'store-destinations',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StoreDestinationsTable, AddDestinationToStoreAction },
  data() {
    return {
      addDestinationToStoreShown: false,
      showDeleteStoreDestinationModal: false,
      storeDestinationIdToDelete: null,
      storeDestinationToDelete: {},
      pagination: 1,
    };
  },
  watch: {
    async store() {
      await this.fetchStoreDestinations();
    },
    storeDestinationsPageNumber() {
      this.pagination = this.storeDestinationsPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'store/changeStoreDestinationsPage',
          success: this.success,
        },
        {
          id: this.store.id,
          page: newValue,
        },
      );
    },
  },
  mounted() {
    this.fetchStoreDestinations();
  },
  computed: {
    ...mapGetters('store', ['store', 'storeDestinations', 'storeDestinationsPageCount', 'storeDestinationsPageNumber', 'storeDestinationsItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showAddDestinationToStoreModal() {
      this.addDestinationToStoreShown = true;
    },
    addDestinationToStoreCanceled() {
      this.addDestinationToStoreShown = false;
    },
    async addDestinationToStoreCompleted() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.destinations.add_action.success' });
      this.addDestinationToStoreShown = false;
    },
    openDeleteStoreDestinationModal(storeDestination) {
      this.storeDestinationToDelete = storeDestination;
      this.showDeleteStoreDestinationModal = true;
    },
    closeDeleteStoreDestinationModal() {
      this.storeDestinationIdToDelete = null;
      this.storeDestinationToDelete = {};
      this.showDeleteStoreDestinationModal = false;
    },
    async deleteStoreDestinationSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `store.destinations.delete_action.success` });
      this.closeDeleteStoreDestinationModal();
    },
    async deleteStoreDestination() {
      await this.executeAction(
        {
          action: 'store/deleteStoreDestination',
          name: 'deleteStoreDestination',
          success: this.deleteStoreDestinationSuccess,
        },
        this.storeDestinationToDelete,
      );
    },
    async fetchStoreDestinations() {
      await this.executeAction({ action: 'store/listStoreDestinations' }, { store_id: this.store.id, page: this.storeDestinationsPageNumber });
    },
  },
};
</script>
